import React from 'react';

const Account = () => {
    return (
        <>
            <h1>Welcome To Your Protected Account Page</h1>
        </>
    );
};

export default Account;
