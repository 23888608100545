import React from 'react';

const Dashboard = () => {
    return (
        <>
            <h1>Welcome to your protected dashboard</h1>
        </>
    );
};

export default Dashboard;
