import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import { navigate } from 'gatsby';
import Dashboard from '../components/App/Dashboard';
import Account from '../components/App/Account';
import useAuth from '../hooks/useAuth';

const App = ({ location }) => {
    const { state, isAuthenticated } = useAuth();
    const redirect = location.pathname.split('/').pop();
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login', { state: { redirect } });
        }
    }, [isAuthenticated, redirect]);

    return (
        <>
            <pre>{JSON.stringify(state, null, 2)}</pre>
            <Router location={location} basepath='/app'>
                <Account path='/account' />
                <Dashboard path='/dashboard' />
            </Router>
        </>
    );
};

export default App;
